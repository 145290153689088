import React, { useState } from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  margin-bottom: 3rem;
`
const Title = styled.div`
  display: flex;
  align-items: center;
  margin: 0 0 0.5rem;
  padding: 0.25rem;

  font-size: 1.2em;
  font-weight: bold;

  cursor: pointer;
  border-bottom: 2px solid ${props => props.theme.color.primary};
`
const Toggle = styled.div`
  position: relative;

  margin-right: 1rem;
  width: 1rem;
  height: 1rem;

  &::before,
  &::after {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all 0.2s ease-in-out;

    display: block;
    height: 2px;
    width: 1rem;

    background-color: ${props => props.theme.color.primary};

    content: "";
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-90deg);
  }

  &[data-active="true"]::before {
    transform: translate(-50%, -50%) rotate(0deg);
  }
`

const Content = styled.div`
  display: none;
  padding: 0.25rem;

  &[data-active="true"] {
    display: block;
  }

  p {
    margin: 0 0 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  a {
    text-decoration: underline;
  }
`

export default function Question(props) {
  const { title, active, children } = props
  const [isActive, setIsActive] = useState(active || false)

  return (
    <Wrapper>
      <Title
        onClick={e => {
          setIsActive(!isActive)
        }}
      >
        <Toggle data-active={isActive} />
        <span>{title}</span>
      </Title>
      <Content data-active={isActive}>{children}</Content>
    </Wrapper>
  )
}
